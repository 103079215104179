import React from 'react';
import DashboardHeader from '../../header';
import {Link} from "react-router-dom";

const Info = ({ setIsOpenSidebar }) => {
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

        <h2 className="main-title text-center h2-titel"> Pharos WebPro &gt; projet nom</h2>

        <div className="bg-white card-box border-20 col-12">
          <h4>Informations générales</h4>
          <div className=" mb-30">
            <div className="mt-30">
              <div className="row">
                <div className="row mb-4">
                  <div className="col-md-6">
                    <label htmlFor="domainInput">Nom de domaine</label>
                    <input type="text" className="form-control" id="domainInput" placeholder="xxxxxx" />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="companyInput">Nom société</label>
                    <input type="text" className="form-control" id="companyInput" placeholder="xxxxxx" />
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-6">
                    <label htmlFor="addressInput">Adresse</label>
                    <input type="text" className="form-control" id="addressInput" placeholder="xxxxxx" />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="phoneInput">Numéro de téléphone</label>
                    <input type="text" className="form-control" id="phoneInput" placeholder="xxxxxx" />
                  </div>
                </div>

                {/* Horaires Section */}
                <div className="row mb-4">
                <div className="col-md-12">
                    <h5>Horaires</h5>
                    <div className="horaires-wrapper border p-3" style={{ borderRadius: '10px' }}>
                        <div className="row mb-2">
                            <div className="col"></div> {/* Empty for labels on the left */}
                            <div className="col text-center">Début matinée</div>
                            <div className="col text-center">Fin matinée</div>
                            <div className="col text-center">Début après-midi</div>
                            <div className="col text-center">Fin après-midi</div>
                        </div>
                        {['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'].map((day) => (
                            <div className="row mb-2 align-items-center" key={day}>
                                <div className="col" style={{ width: '100px' }}>{day}</div>
                                <div className="col">
                                    <input type="text" className="form-control" placeholder="xxxxxx" />
                                </div>
                                <div className="col">
                                    <input type="text" className="form-control" placeholder="xxxxxx" />
                                </div>
                                <div className="col">
                                    <input type="text" className="form-control" placeholder="xxxxxx" />
                                </div>
                                <div className="col">
                                    <input type="text" className="form-control" placeholder="xxxxxx" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="aboutInput">A propos</label>
                    <textarea type="text" className="form-control" id="aboutInput" placeholder="xxxxxx" />
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
            </div>
          </div>

          {/* Logo section */}
          <h4><u>Logo</u></h4>
          <div className="text-center mb-30">
            <div className="d-flex justify-content-center mt-30">
              {/* Logo display */}
              <img
                src="./assets/images/logo/logo-pharos.png"
                alt="logo"
                width="100"
                priority="true"
              />
            </div>
            {/* <div className="d-flex justify-content-center mt-4">
              <a href="#" className="dash-btn-two tran3s me-3">Modifier</a>
            </div> */}
          </div>

          {/* Gallery Images section */}
          <h4><u> Galleries images</u></h4>
          <div className="text-center mb-30">
            <div className="d-flex justify-content-center mt-30 row">
              {/* Display gallery images */}
              <div className='col-md-3'>
                <div className="gallery-item gallery-item-card">
                  <img src="./assets/images/logo/logo-pharos.png" style={{paddingTop:"30%"}} className='mx-auto d-block ' alt="image1"  width="100" />
                  {/* <button className="delete-mail me-3"><img src="./assets/dashboard/images/icon/icon_35.svg" alt="icon" className="lazy-img delete_img" /></button> */}
                </div>
              </div>
              <div className='col-md-3'>
                <div className="gallery-item gallery-item-card">
                  <img src="./assets/images/logo/logo-pharos.png" style={{paddingTop:"30%"}} className='mx-auto d-block ' alt="image1"  width="100" />
                  {/* <button className="delete-mail me-3"><img src="./assets/dashboard/images/icon/icon_35.svg" alt="icon" className="lazy-img delete_img" /></button> */}
                </div>
              </div>
              <div className='col-md-3'>
                <div className="gallery-item gallery-item-card">
                  <img src="./assets/images/logo/logo-pharos.png" style={{paddingTop:"30%"}} className='mx-auto d-block ' alt="image1"  width="100" />
                  {/* <button className="delete-mail me-3"><img src="./assets/dashboard/images/icon/icon_35.svg" alt="icon" className="lazy-img delete_img" /></button> */}
                </div>
              </div>
              <div className='col-md-3'>
                <div className="gallery-item gallery-item-card">
                  <img src="./assets/images/logo/logo-pharos.png" style={{paddingTop:"30%"}}   className='mx-auto d-block ' alt="image1"  width="100" />
                  {/* <button className="delete-mail me-3"><img src="./assets/dashboard/images/icon/icon_35.svg" alt="icon" className="lazy-img delete_img" /></button> */}
                </div>
              </div>
            </div>
           
          </div>

          {/* Save button */}
          <div className="d-flex justify-content-center mt-4">
            <Link to="/package-com1" className="dash-btn-two tran3s me-3">Modifier le package</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
