import React from 'react';
import DashboardHeader from '../../header';
/* import '@fortawesome/fontawesome-free/css/all.min.css'; */

// props type 

const InformationsSociete = ({setIsOpenSidebar}) => {
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}
        <h2 className="main-title  h2-titel text-center">Informations de la société <br /> nom-projet</h2>         


        <div className="bg-white card-box border-20">
        <div className="container">
  <div className="row">
    {/* Fiche dirigeant */}
    <div className="col-md-6">
      <h4>Fiche dirigeant</h4>
      <div className="mb-4">
        <input type="text" className="form-control" id="firstNameInput" placeholder="Prénom" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="lastNameInput" placeholder="Nom" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="birthPlaceInput" placeholder="Lieu de naissance" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="birthDateInput" placeholder="Date de naissance" />
      </div>
      <div className="mb-4">
        <input type="email" className="form-control" id="emailInput" placeholder="Email" />
      </div>
      <div className="mb-4">
        <input type="tel" className="form-control" id="phoneInput" placeholder="Tel" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="addressInput" placeholder="Adresse" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="personalSituationInput" placeholder="Situation personnelle" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="professionalSituationInput" placeholder="Situation professionnelle" />
      </div>
    </div>

    {/* Conjoint(e) */}
    <div className="col-md-6">
      <h4>Conjoint(e)</h4>
      <div className="mb-4">
        <input type="text" className="form-control" id="spouseFirstNameInput" placeholder="Prénom" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="spouseLastNameInput" placeholder="Nom" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="spouseMaidenNameInput" placeholder="Nom de jeune fille" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="spouseBirthPlaceInput" placeholder="Lieu de naissance" />
      </div>
      <div className="mb-4">
        <input type="text" className="form-control" id="spouseBirthDateInput" placeholder="Date de naissance" />
      </div>
    </div>
  </div>
</div>

  <hr />

  <h4>Document à fournir</h4>
  <div className="table-responsive mt-20 mb-20" id="liste-table">
    <table className="table job-alert-table">
      <tbody>
        <tr>
          <td className="text-left p-2">nom pièce 1</td>
          <td className="text-center p-2">
            <i className="fas fa-file-alt text-success fs-4"></i>
          </td>
          <td className="text-center p-2">En attente/validé/rejeté</td>
          <td className="text-center p-2">message du cabinet comptable</td>
          <td className="text-center p-2">charger</td>
        </tr>
        <tr>
          <td className="text-left p-2">nom pièce 2</td>
          <td className="text-center  p-2">
            <i className="fas fa-file-alt text-danger fs-4"></i>
          </td>
          <td className="text-center p-2">En attente/validé/rejeté</td>
          <td className="text-center p-2">message du cabinet comptable</td>
          <td className="text-center p-2">charger</td>
        </tr>
      </tbody>
    </table>
  </div>

  <h4>Fiche entreprise</h4>
  <div className="row mb-4">
    <div className="col-md-6">
      <label htmlFor="companyStatus">Statut</label>
      <input type="text" className="form-control" id="companyStatus" placeholder="xxxxxx" />
    </div>
    <div className="col-md-6">
      <label htmlFor="companyName">Dénomination</label>
      <input type="text" className="form-control" id="companyName" placeholder="xxxxxx" />
    </div>
  </div>
  <div className="row mb-4">
    <div className="col-md-12">
      <label htmlFor="addressInput">Adresse du siège social juridique</label>
      <input type="text" className="form-control" id="addressInput" placeholder="xxxxxx" />
    </div>
  </div>
 <div className="row mb-4">
    <div className="col-md-6">
      <label htmlFor="companyPurpose">Objet</label>
      <input type="text" className="form-control" id="companyPurpose" placeholder="xxxxxx" />
    </div>
    <div className="col-md-6">
      <label htmlFor="companyCapital">Capital</label>
      <input type="text" className="form-control" id="companyCapital" placeholder="xxxxxx" />
    </div>
  </div>
  <div className="row mb-4">
    <div className="col-md-6">
      <label htmlFor="companyShares">Nombre de parts</label>
      <input type="text" className="form-control" id="companyShares" placeholder="xxxxxx" />
    </div>
    <div className="col-md-6">
      <label htmlFor="companyPartners">Nombre d'associés</label>
      <input type="text" className="form-control" id="companyPartners" placeholder="xxxxxx" />
    </div>
  </div>
  <hr className="mb-30 mt-30" />

  <h4>Fiche associés</h4>
  <div className="row mb-4">
    <div className="col-md-6">
      <label htmlFor="associateFirstName">Prénom</label>
      <input type="text" className="form-control" id="associateFirstName" placeholder="xxxxxx" />
    </div>
    <div className="col-md-6">
      <label htmlFor="associateLastName">Nom</label>
      <input type="text" className="form-control" id="associateLastName" placeholder="xxxxxx" />
    </div>
  </div>
  <div className="row mb-4">
    <div className="col-md-6">
      <label htmlFor="associateBirthPlace">Lieu de naissance</label>
      <input type="text" className="form-control" id="associateBirthPlace" placeholder="xxxxxx" />
    </div>
    <div className="col-md-6">
      <label htmlFor="associateBirthDate">Date de naissance</label>
      <input type="text" className="form-control" id="associateBirthDate" placeholder="xxxxxx" />
    </div>
  </div>
  <div className="row mb-4">
    <div className="col-md-6">
      <label htmlFor="associateEmail">Email</label>
      <input type="email" className="form-control" id="associateEmail" placeholder="xxxxxx" />
    </div>
    <div className="col-md-6">
      <label htmlFor="associatePhone">Tel</label>
      <input type="number" className="form-control" id="associatePhone" placeholder="xxxxxx" />
    </div>
  </div>
  <div className="row mb-4">
    <div className="col-md-12">
      <label htmlFor="addressInput">Adresse</label>
      <input type="text" className="form-control" id="addressInput" placeholder="xxxxxx" />
    </div>
  </div>
  <div className="row mb-4">
    <div className="col-md-6">
      <label htmlFor="associatePersonalSituation">Situation personnelle</label>
      <input type="text" className="form-control" id="associatePersonalSituation" placeholder="xxxxxx" />
    </div>
    <div className="col-md-6">
      <label htmlFor="associateProfessionalSituation">Situation professionnelle</label>
      <input type="text" className="form-control" id="associateProfessionalSituation" placeholder="xxxxxx" />
    </div>
  </div>

  <div className="table-responsive mt-20 mb-20" id="liste-table">
  <table className="table job-alert-table">
      <tbody>
        <tr>
          <td className="text-left p-2">nom pièce 1</td>
          <td className="text-center p-2">
            <i className="fas fa-file-alt text-success fs-4"></i>
          </td>
          <td className="text-center p-2">En attente/validé/rejeté</td>
          <td className="text-center p-2">message du cabinet comptable</td>
          <td className="text-center p-2">charger</td>
        </tr>
        <tr>
          <td className="text-left p-2">nom pièce 2</td>
          <td className="text-center  p-2">
            <i className="fas fa-file-alt text-danger fs-4"></i>
          </td>
          <td className="text-center p-2">En attente/validé/rejeté</td>
          <td className="text-center p-2">message du cabinet comptable</td>
          <td className="text-center p-2">charger</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
                  

      </div>   

 

    </div>
  );
};

export default InformationsSociete;