import React from 'react';
import DashboardHeader from '../header';
import { Link } from 'react-router-dom';

const Communication = ({setIsOpenSidebar}) => {
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

        <div className="d-flex align-items-center justify-content-between mb-40 lg-mb-30">
          <h2 className="main-title m0 h2-titel">Pharos WebPro</h2>          
        </div>

        <div className="bg-white card-box border-20">
          <div className="row justify-content-center">
            <div className="button-group d-inline-flex col-12 align-items-center justify-content-center">
                <Link to="/info-projet" className="dash-btn-two tran3s me-3 card_dash">Projet 1</Link>          
            </div>  
            <div className="button-group d-inline-flex col-12 mt-4 align-items-center justify-content-center">
              <Link to="#" className="dash-btn-two tran3s me-3 card_dash">Projet 2</Link>          
            </div>               
            <div className="button-group d-inline-flex col-4 mt-100 align-items-center justify-content-center">
              <Link to="/paiement-package-web" className="dash-btn-two tran3s me-3 card_dash">Commander un nouveau projet</Link>          
            </div>               
          </div>
        </div>
          
      </div>      
    </div>
  );
};

export default Communication;