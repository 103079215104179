"use client";
import React from "react";
import { Link } from "react-router-dom";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineDot, TimelineContent } from '@mui/lab';
import { FaCheckCircle, FaStar, FaThumbsUp, FaInfoCircle } from 'react-icons/fa';
import { Grid, Paper, Typography, Button, Container } from '@mui/material';
import { Stack } from '@mui/material';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BalanceIcon from '@mui/icons-material/Balance';
import TaskIcon from '@mui/icons-material/Task';

const CandidateV3Area = () => {
  // Sample card data with links
  const cardData = [
    { id: 1, title: 'lien site maquette', image: 'https://via.placeholder.com/300x200', link: '/maquette-1' },
    { id: 2, title: 'lien site maquette', image: 'https://via.placeholder.com/300x200', link: '/maquette-2' },
    { id: 3, title: 'lien site maquette', image: 'https://via.placeholder.com/300x200', link: '/maquette-3' },
  ];

  function PositionedTimeline() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  
    return (
      <>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: isSmallScreen
              ? {
                  flex: 0,
                  padding: 0,
                }
              : undefined,
          }}
          position={isSmallScreen ? undefined : "alternate-reverse"}
        >
          <TimelineItem sx={{ alignItems: "center" }}>
            <TimelineSeparator>
              <TimelineConnector
                sx={{ height: "50px", background: "transparent" }}
              />
              <TimelineDot
                sx={{
                  backgroundColor: "rgb(162, 221, 199)",
                  borderColor: "white",
                  borderWidth: 5,
                  borderStyle: "solid",
                  width: 45,
                  height: 45,
                  margin: 0,
                }}
              />
              <TimelineConnector sx={{ height: "50px" }} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} sx={{ padding: 1 }}>
                <Stack spacing={2} direction="row" alignItems={"center"}>
                  <ReceiptLongIcon
                    sx={{ color: "rgb(162, 221, 199)", fontSize: "40px" }}
                  />
                  <Typography align="right" style={{ fontSize:'font-size: 1rem !important' }}>
                  Étape 1              
                   </Typography>
                </Stack>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem sx={{ alignItems: "center" }}>
            <TimelineSeparator>
              <TimelineConnector sx={{ height: "50px" }} />
              <TimelineDot
                sx={{
                  backgroundColor: "rgb(162, 221, 199)",
                  borderColor: "white",
                  borderWidth: 5,
                  borderStyle: "solid",
                  width: 45,
                  height: 45,
                  margin: 0,
                }}
              />
              <TimelineConnector sx={{ height: "50px" }} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} sx={{ padding: 1 }}>
                <Stack spacing={2} direction="row" alignItems={"center"}>
                  <RecordVoiceOverIcon
                    sx={{ color: "rgb(162, 221, 199)", fontSize: "40px" }}
                  />
                  <Typography align="left">
                  Étape 2
                  </Typography>
                </Stack>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem sx={{ alignItems: "center" }}>
            <TimelineSeparator>
              <TimelineConnector sx={{ height: "50px" }} />
              <TimelineDot
                sx={{
                  backgroundColor: "rgb(162, 221, 199)",
                  borderColor: "white",
                  borderWidth: 5,
                  borderStyle: "solid",
                  width: 45,
                  height: 45,
                  margin: 0,
                }}
              />
              <TimelineConnector sx={{ height: "50px" }} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} sx={{ padding: 1 }}>
                <Stack spacing={2} direction="row" alignItems={"center"}>
                  <AccountBalanceIcon
                    sx={{ color: "rgb(162, 221, 199)", fontSize: "40px" }}
                  />
                  <Typography align="left">
                  Étape 4
                  </Typography>
                </Stack>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem sx={{ alignItems: "center" }}>
            <TimelineSeparator>
              <TimelineConnector sx={{ height: "50px" }} />
              <TimelineDot
                sx={{
                  backgroundColor: "rgb(162, 221, 199)",
                  borderColor: "white",
                  borderWidth: 5,
                  borderStyle: "solid",
                  width: 45,
                  height: 45,
                  margin: 0,
                }}
              />
              <TimelineConnector sx={{ height: "50px" }} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} sx={{ padding: 1 }}>
                <Stack spacing={2} direction="row" alignItems={"center"}>
                  <BalanceIcon
                    sx={{ color: "rgb(162, 221, 199)", fontSize: "40px" }}
                  />
                  <Typography align="left">
                     Étape 4
                  </Typography>
                </Stack>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem sx={{ alignItems: "center" }}>
            <TimelineSeparator>
              <TimelineConnector sx={{ height: "50px" }} />
              <TimelineDot
                sx={{
                  backgroundColor: "rgb(162, 221, 199)",
                  borderColor: "white",
                  borderWidth: 5,
                  borderStyle: "solid",
                  width: 45,
                  height: 45,
                  margin: 0,
                }}
              />
              <TimelineConnector
                sx={{ height: "50px", background: "transparent" }}
              />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} sx={{ padding: 1 }}>
                <Stack spacing={2} direction="row" alignItems={"center"}>
                  <TaskIcon
                    sx={{ color: "rgb(162, 221, 199)", fontSize: "40px" }}
                  />
                  <Typography align="left">Étape 5</Typography>
                </Stack>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </>
    );
  }

  return (
    <>
      <div className="hero-banner-two position-relative background_section">
        <div className="container">
          <div className="position-relative pb-80 lg-pb-60" style={{ paddingTop: '130px', paddingBottom: '20px' }}>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <h2 className="wow fadeInUp text-center" data-wow-delay="0.3s">Titre</h2>
                <p className="text-md mt-35 mb-50 lg-mb-30  fadeInUp text-center">sous-titre</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="blog-section-two pt-30 xl-pt-30 lg-pt-30 pb-20">
        <div className="container">
          <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp">
            <h2 className="fw-600 mb-3">Créer mon package commercial</h2>
            <h4 className="fw-600 mb-3">De quoi est constitué le package commercial ?</h4>
          </div>
          <div className="row mt-5 mb-30">
            <div className="col-12">
              <ul className="list-group">
                <li className="list-group-item">1 -------------</li>
                <li className="list-group-item">2 -------------</li>
                <li className="list-group-item">3 -------------</li>
                <li className="list-group-item">4 -------------</li>
              </ul>                            
            </div>
          </div>
        </div>
        <section className="background_section py-3">              
          <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp mt-4">
              <h2 className="fw-600">Comment ça marche ?</h2>
            </div>
            <PositionedTimeline />
          <button type="submit" className="btn-eleven fw-500 tran3s d-block mt-20">
            <Link to="/paiement-package-web" className="d-block">
              Créer mon package commercial
            </Link>
          </button>
        </section>
        <br /> <br />
        <div className="container">
          <div className="position-relative">
            <div className="title-one text-center mb-30 lg-mb-10 wow fadeInUp" data-wow-delay="0.3s">
              <h2 className="fw-600">Exemple de sites vitrine</h2>
            </div>
            <div className="row gx-xxl-5">
              {cardData.map((card) => (
                <div key={card.id} className="col-lg-4 col-md-6 mb-4">
                  <Link to={card.link} className="text-decoration-none">
                    <div className="card shadow-sm">
                      <img src={card.image} className="card-img-top" alt={card.title} />
                      <div className="card-body text-center">
                        <h5 className="card-title">{card.title}</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CandidateV3Area;
