"use client"
import React from 'react';
import {Link} from 'react-router-dom';
import DashboardHeader from '../../header';

const Factures = ({setIsOpenSidebar}) => {    
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

        <h2 className="main-title h2-titel">Ma facturation &gt; Projet nom &gt; Créer une facture</h2>
        <div className="bg-white card-box border-20 mb-20">
            <div className="row mb-20">
                <div className="col-md-6">
                    <h5>Logo + info ma société</h5>
                </div>
                
                <div className="col-md-6 ">
                    <input type="text" className="form-control mb-3" id="" placeholder="Titre" />
                    <h5><Link to="/ma-facturation-infos-societe-client" className='text-reset'>Info société client</Link></h5>
                </div>
                
            </div>
            <hr />
            <div className='row mt-3'>
                <div className="col-md-6">
                    
                    <input type="date" className="form-control mb-3" id="" placeholder="Date" />
                </div>
                <div className="col-md-6">
                    <input type="number" className="form-control mb-3" id="" placeholder="numéro" />
                </div>
            </div>
                <div className="col-md-12 mt-10">
                <div className="table-responsive" id="liste-table">
                    <table className="table job-alert-table">
                    <thead>
                        <tr>
                        <th scope="col" className='text-center'>Réference</th>
                        <th scope="col" className='text-center'>Désignation </th>
                        <th scope="col" className='text-center'>Quantité</th>
                        <th scope="col" className='text-center'>PU Vente </th> 
                        <th scope="col" className='text-center'>TVA</th>  
                        <th scope="col" className='text-center'>Montant HT</th>                                  
                        <th scope="col" className='text-center'>Image</th>                                  
                                
                        </tr>
                    </thead>
                    <tbody className="border-0">
                        <tr>
                        <td className='text-center'></td>                
                        <td className='text-center'>Mission data</td>                
                        <td className='text-center'>10</td>                
                        <td className='text-center'>100$</td>                                
                        <td className='text-center'>20</td>                
                        <td className='text-center'>1000$</td> 
                        <td className='text-center'></td>  
                        <i className="bi bi-x-circle-fill text-danger"></i>
                         
                        </tr>            
                        <tr>
                            <td className='text-center'></td>                
                            <td className='text-center'>Mission data</td>                
                            <td className='text-center'>10</td>                
                            <td className='text-center'>100$</td>                                
                            <td className='text-center'>20</td>                
                            <td className='text-center'>1000$</td> 
                            <td className='text-center'></td>                             
                            <i className="bi bi-x-circle-fill text-danger"></i>

                        </tr>            
                    </tbody>
                    </table>
                </div>  
                </div>
          
            <div className="button-group d-flex mt-30 mb-3 ">
                <a href="#" className="dash-btn-two tran3s me-3">Ajouter un produit/service</a>                
            </div>
            <hr />
            <div className='row mt-3'>
               
                <div className="col-md-6">
                    <input type="number" className="form-control mb-3" id="" placeholder="Montant TTC" />
                </div>
                <div className="col-md-6">
                    <input type="text" className="form-control mb-3" id="" placeholder="Coordonnées bancaires" />
                </div>
                <div className="col-md-6">
                <h5><Link to="/ma-facturation-factures-conditions-générales" className='text-reset'>conditions générales</Link></h5>
                </div>
                
            </div>
            
            <div className="button-group d-flex justify-content-center mt-30">
                <Link to="/ma-facturation-factures" className="dash-btn-two tran3s me-3">Enregistrer le brouillon</Link>  
                <Link to="/ma-facturation-factures" className="dash-btn-two tran3s me-3">Valider et générer la facture</Link>                
              
            </div>
        </div>
       

      </div>
    </div>
  );
};

export default Factures;