import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import DashboardHeader from "../header";
import { Modal, Button, Card, Dropdown } from 'react-bootstrap';

const SubmitJobArea = ({ setIsOpenSidebar }) => {
  const [folders, setFolders] = useState(['dossier1', 'dossier2']);
  const [newFolderName, setNewFolderName] = useState('');
  const [file, setFile] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState('');
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleFolderChange = (e) => setSelectedFolder(e.target.value);

  const handleNewFolderNameChange = (e) => setNewFolderName(e.target.value);

  const handleAddNewFolder = () => {
    if (newFolderName.trim() === '') return;
    setFolders([...folders, newFolderName]);
    setNewFolderName('');
    setSelectedFolder(newFolderName);
  };
  const createIcon = 'https://img.icons8.com/fluency/24/000000/add-folder.png';
  const duplicateIcon = 'https://img.icons8.com/fluency/24/000000/copy.png';
  const deleteIcon = 'https://img.icons8.com/fluency/24/000000/delete-folder.png';
  const shareIcon = 'https://img.icons8.com/fluency/24/000000/share.png';
  
  const handleFileUpload = (e) => setFile(e.target.files[0]);

  const handleEventClick = () => setShowModal(true);

  const handleCloseModal = () => setShowModal(false);

  const toggleDropdown = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };


  return (
    <>
      <div className="dashboard-body">
        <div className="position-relative">
          <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />

          <h3 className="main-title text-center h2-title">GED &gt; projet WebPro nom</h3>

          <div className="row mt-4">
            {[
              { name: "Hyper-sketch.zip", size: "2.3 MB" },
              { name: "Compile Version", size: "87.2 MB" },
              { name: "admin.zip", size: "45.1 MB" },
              { name: "Docs.pdf", size: "7.5 MB" },
              { name: "License-details.pdf", size: "784 KB" },
              { name: "Purchase Verification", size: "2.2 MB" },
              { name: "Hyper Integrations", size: "874 MB" }
            ].map((file, index) => (
              <div key={index} className="col-md-3 mb-3">
                <Card className="h-40">
                  <Card.Body className="position-relative">
                    <Card.Title className='fadeInUp'>{file.name}</Card.Title>
                    <Card.Text>{file.size}</Card.Text>

                    <div className="action-dots float-end" style={{ top: '0', right: '0' }}>
                      <button
                        className="action-btn dropdown-toggle position-absolute"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ top: '10px', right: '10px' }}
                      >
                        <span></span>
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end custom-dropdown-menu">
                        <li>
                          <Link className="dropdown-item" to="#" onClick={handleEventClick}>
                            <img src="./assets/dashboard/images/icon/icon_3.svg" alt="icon" className="lazy-img" /> Ajouter
                          </Link>
                        </li>
                       
                        <li>
                          <Link className="dropdown-item" to="">
                            <img src="./assets/dashboard/images/icon/icon_20.svg" alt="icon" className="lazy-img" /> Modifier
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="">
                            <img src="./assets/dashboard/images/icon/icon_21.svg" alt="icon" className="lazy-img" />{" "}
                            Supprimer
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>

          <button className="btn btn-success mt-3">Nouveau</button>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un fichier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label htmlFor="folderSelect" className="form-label">Choisir un dossier</label>
              <select
                required
                name="departmentid"
                id="folderSelect"
                className="form-control"
                aria-required="true"
                value={selectedFolder}
                onChange={handleFolderChange}
              >
                <option value="">---</option>
                {folders.map((folder, index) => (
                  <option key={index} value={folder}>
                    {folder}
                  </option>
                ))}
                <option value="add-new">Ajouter un nouveau dossier</option>
              </select>
            </div>

            {selectedFolder === 'add-new' && (
              <div className="mb-3">
                <label htmlFor="newFolder" className="form-label">Nom du nouveau dossier</label>
                <div className="input-group">
                  <input
                    type="text"
                    id="newFolder"
                    className="form-control"
                    placeholder="Nom du nouveau dossier"
                    value={newFolderName}
                    onChange={handleNewFolderNameChange}
                  />
                  <Button
                    variant="primary"
                    className="ms-2"
                    onClick={handleAddNewFolder}
                  >
                    Ajouter
                  </Button>
                </div>
              </div>
            )}

            {selectedFolder && selectedFolder !== 'add-new' && (
              <div className="mb-3">
                <label htmlFor="fileUpload" className="form-label">Charger un fichier</label>
                <input
                  type="file"
                  id="fileUpload"
                  className="form-control"
                  onChange={handleFileUpload}
                />
              </div>
            )}

            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Fermer
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>

      <style>
        {`
          .custom-dropdown-menu {
            top: auto;
            bottom: 100%; /* Afficher le dropdown en haut */
            right: 0;
          }

          .dropdown-menu {
            position: absolute;
            z-index: 1000;
          }
        `}
      </style>
    </>
  );
};

export default SubmitJobArea;
