import React from "react";
import {Link} from 'react-router-dom';
import DashboardHeader from "../header";
import CandidateItem from "./candidate-item";

const candidate_data = [
  {
    id:1,
    img:"./assets/images/candidates/img_01.jpg",
    name:'Projet 1',
    post:'Graphic Designer',
    skills:['Digital','Design','UI'],
    salary:'01/08/2024',
    location:'10000',
    salary_duration:'€',
    experience:'Fresher',
    favorite:true,
    qualification:'Master’s Degree',
  },
  {
    id:2,
    img:"./assets/images/candidates/img_02.jpg",
    name:'Projet 2',
    post:'Javascript Developer',
    skills:['Java','Developer','code','front-end'],
    salary:'05/08/2024',
    location:'15100',
    salary_duration:'€',
    experience:'Intermediate',
    qualification:'Master’s Degree',
  },
  {
    id:3,
    img:"./assets/images/candidates/img_03.jpg",
    name:'Projet 3',
    post:'UI/UX Designer',
    skills:['Design','Product','UI','Brand'],
    salary:'15/07/2024',
    location:'25100',
    salary_duration:'€',
    experience:'No-Experience',
    favorite:true,
    qualification:'Bachelor Degree',
  },
]


const MesAchatsIndex = ({setIsOpenSidebar}) => {
  const candidate_items = candidate_data.slice(0, 4);
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

        <div className="d-flex align-items-center justify-content-between mb-40 lg-mb-30">
          <h2 className="main-title m0 h2-titel">Mes achats</h2>          
        </div>

        <div className="wrapper">
          {candidate_items.map((item) => (
            <CandidateItem key={item.id} item={item} />
          ))}
        </div>

        <div className="dash-pagination d-flex justify-content-end mt-30">
          <ul className="style-none d-flex align-items-center">
            <li>
              <Link href="#" className="active">
                1
              </Link>
            </li>
            <li>
              <Link href="#">2</Link>
            </li>
            <li>
              <Link href="#">3</Link>
            </li>
            <li>..</li>
            <li>
              <Link href="#">7</Link>
            </li>
            <li>
              <Link href="#">
                <i className="bi bi-chevron-right"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MesAchatsIndex;
