import React from 'react';
import DashboardHeader from '../../header';
import { Link } from 'react-router-dom';

const DemarchesCreate = ({setIsOpenSidebar}) => {
  return (    
    <div className="dashboard-body">
      <div className="position-relative">
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

          <h2 className="main-title text-center h2-titel">Démarche récapitulatif</h2>         
        
        <div className="row">
          <div className="bg-white card-box border-20 col-12 d-flex justify-content-center mb-30">                          
              <div className="button-group d-inline-flex align-items-center mt-30">
                  <h5>Statut du projet :</h5><h5 className="text-success"> A compléter/Validé/Rejeté</h5>
              </div> 
          </div> 
        </div> 

        <div className="row">
            <div className="bg-white card-box border-20 col-12 d-flex justify-content-center">                          
                <div className="button-group d-inline-flex align-items-center mt-30">
                   <Link to="/dashboard-mes-demarches-info-societe" className="dash-btn-two tran3s me-3">Prendre rdv avec nos experts</Link>          
                </div> 
            </div>                       
            <div className="bg-white card-box border-20 col-12 mt-4 d-flex justify-content-center">          
                <div className="button-group d-inline-flex align-items-center mt-30">
                    <Link to="/dashboard-mes-demarches-info-societe" className="dash-btn-two tran3s me-3">Informations sur la société</Link>          
                </div> 
            </div>                       
            <div className="bg-white card-box border-20 col-12 mt-4 d-flex justify-content-center">          
                <div className="button-group d-inline-flex align-items-center mt-30">
                   <Link to="/mes-demarches-document-a-signer" className="dash-btn-two tran3s me-3">Documents à signer</Link>          
                </div> 
            </div>                       
        </div>              
      </div>      
      <style jsx>{`
       .dashboard-body .card-box {
          padding: 0px 60px 25px;
        }
      `}</style>
    </div>
  );
};

export default DemarchesCreate;