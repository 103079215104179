"use client"
import React from 'react';
import DashboardHeader from '../../header';
import { Link } from 'react-router-dom';

const FacturesIndex = ({setIsOpenSidebar}) => {

  const handleHistoriqueClick = () => {    
    const historiqueTable = document.getElementById('historique-table');
    if (historiqueTable) {
      historiqueTable.style.display = 'block';
    }    
    const listeTable = document.getElementById('liste-table');
    if (listeTable) {
      listeTable.style.display = 'none';
    }    
    const historiqueBtn = document.getElementById('historique-btn');
    if (historiqueBtn) {
      historiqueBtn.style.display = 'none';
    }    
    const listeBtn = document.getElementById('liste-btn');
    if (listeBtn) {
      listeBtn.style.display = 'block';
    }    
  };
  const handleListeClick = () => {    
    const historiqueTable = document.getElementById('historique-table');
    if (historiqueTable) {
      historiqueTable.style.display = 'none';
    }    
    const listeTable = document.getElementById('liste-table');
    if (listeTable) {
      listeTable.style.display = 'block';
    }    
    const historiqueBtn = document.getElementById('historique-btn');
    if (historiqueBtn) {
      historiqueBtn.style.display = 'block';
    }    
    const listeBtn = document.getElementById('liste-btn');
    if (listeBtn) {
      listeBtn.style.display = 'none';
    }    
  };
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

        <h2 className="main-title h2-titel">Mes factures</h2>                 

        <div className="bg-white card-box border-20"> 
          <div className="d-flex justify-content-between mb-4"> 
            <a href="#" className="dash-btn-two tran3s me-3" id='historique-btn' onClick={handleHistoriqueClick}>Historique</a>   
            <a href="#" className="dash-btn-two tran3s me-3" id='liste-btn' style={{ display: 'none' }} onClick={handleListeClick}>Liste facture</a>
            <Link to="/ma-facturation-factures-create" className="dash-btn-two tran3s me-3" >Créer une facture</Link>  
          </div>                                

          <div className="table-responsive" id="liste-table">
            <h4>Liste des factures</h4>
            <table className="table job-alert-table">
              <thead>
                <tr>
                  <th scope="col" className='text-center'>N° Facture</th>
                  <th scope="col" className='text-center'>Nom Client </th>
                  <th scope="col" className='text-center'>Statut</th>
                  <th scope="col" className='text-center'>Date </th>
                  <th scope="col" className='text-center'>Montant </th> 
                  <th scope="col" className='text-center'>Facture PDF</th>                                  
                </tr>
              </thead>
              <tbody className="border-0">
                <tr>
                  <td className='text-center'>Facture 1</td>                
                  <td className='text-center'>nom client</td>  
                  <td className='text-center'>Validé</td>                                                              
                  <td className='text-center'>01/08/2024</td>                
                  <td className='text-center'>12000</td> 
                   <td className='text-center'><i className="fas fa-file-alt text-success"></i></td> 
                                    
                </tr>            
                <tr>
                  <td className='text-center'>Facture 2</td>                
                  <td className='text-center'>nom client</td>  
                  <td className='text-center'>Brouillon </td>                                                              
                  <td className='text-center'>01/08/2024</td>                
                  <td className='text-center'>12000</td> 
                   <td className='text-center'><i className="fas fa-file-alt text-danger"></i></td> 
                                    
                </tr>
                         
              </tbody>
            </table>
          </div>  

          <div className="table-responsive" id="historique-table" style={{ display: 'none' }}>
            <h4>Historique des factures</h4>
            <table className="table job-alert-table">
              <thead>
                <tr>
                  <th scope="col" className='text-center'>Facture</th>
                  <th scope="col" className='text-center'>Client </th>
                  <th scope="col" className='text-center'>Date </th>
                  <th scope="col" className='text-center'>Montant </th>                  
                </tr>
              </thead>
              <tbody className="border-0">
                <tr>
                  <td className='text-center'>Facture 1</td>                
                  <td className='text-center'>nom client</td>                                
                  <td className='text-center'>01/08/2024</td>                
                  <td className='text-center'>12000</td>                                
                </tr>                                        
              </tbody>
            </table>
          </div>

        </div> 

      </div>
    </div>
  );
};

export default FacturesIndex;