import React from 'react';
import DashboardHeader from '../header';
import { Link } from 'react-router-dom';

const FacturationIndex = ({setIsOpenSidebar}) => {
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

        <h2 className="main-title h2-titel">Pharos Compta (Ma facturation) &gt; Projet nom</h2>
        
            <div className="bg-white card-box border-20">
                <div className="row justify-content-center">                    
                    <div className="button-group d-inline-flex col-12 align-items-center justify-content-center">
                        <Link to="/ma-facturation" className="dash-btn-two tran3s  me-3 card_dash">projet 1</Link>          
                    </div>  
                    <div className="button-group d-inline-flex col-12 mt-4 align-items-center justify-content-center">
                        <Link to="" className="dash-btn-two tran3s me-3 card_dash">projet 2</Link>          
                    </div>    
                    <div className="button-group d-inline-flex col-4 mt-100 align-items-center justify-content-center">
                      <Link to="/creer-nouveau-projet" className="dash-btn-two tran3s me-3 card_dash">Créer un nouveau projet</Link>          
                    </div>                                                         
                </div>                            
            </div>
      </div>
    </div>
  );
};

export default FacturationIndex;