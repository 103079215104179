"use client"
import React from 'react';
import {Link} from 'react-router-dom';
import DashboardHeader from '../../header';

const RechecherSociete = ({setIsOpenSidebar}) => {    
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        <h2 className="main-title h2-titel">Ma facturation &gt; Projet nom &gt;Créer une facture</h2>
        <div className="bg-white card-box border-20">
          <div className="row justify-content-center">   
    <form className="text-center">
      <h4 className="d-flex flex-column align-items-center mb-4">
        <Link to='/ma-facturation' className="text-reset">
          <u>Rechercher une société</u>
        </Link>
      </h4>
      
      <p className="text-center">
        A partir du registre de l’état, notre outil est capable de retrouver vos clients à partir d’une partie du nom ou du numéro de siret de l’entreprise ou du nom du dirigeant
      </p>

      <div className='row justify-content-center'>
        <div className="mb-3 col-md-8">
          <input className="form-control mb-3" type="text" placeholder='Renseignez un nom d’entreprise, d’un dirigeant ou du numéro de siret' />
        </div>
      </div>

      <div className="form-check text-start mb-3">
        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
        <label className="form-check-label" htmlFor="flexRadioDefault1">
          Nom Société<br /> N° Siret<br /> Nom du dirigeant<br /> Adresse
        </label>
      </div>
      <div className="form-check text-start mb-3">
        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
        <label className="form-check-label" htmlFor="flexRadioDefault2">
          Nom Société<br /> N° Siret<br /> Nom du dirigeant<br /> Adresse
        </label>
      </div>
      <div className="form-check text-start mb-3">
        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
        <label className="form-check-label" htmlFor="flexRadioDefault3">
          Nom Société<br /> N° Siret<br /> Nom du dirigeant<br /> Adresse
        </label>
      </div>

      <div className="button-group d-inline-flex align-items-center justify-content-center">
        <Link to="/ma-facturation-factures-create" className="text-reset me-3 card_dash">Annuler</Link>          
        <Link to="/ma-facturation-factures-create" className="dash-btn-two tran3s me-3 card_dash">Ajouter</Link>          
      </div>
    </form>                                                         
          </div>
        </div>

      </div>
    
    </div>
    
  );
};

export default RechecherSociete;