import React from 'react';
import DashboardHeader from '../header';
import { Link } from 'react-router-dom';

const MailIndex = ({setIsOpenSidebar}) => {
  return (
    <div className="dashboard-body">
      <div className="position-relative">
        {/* header start */}
        <DashboardHeader setIsOpenSidebar={setIsOpenSidebar} />
        {/* header end */}

        <div className="d-flex align-items-center justify-content-between mb-40 lg-mb-30">
          <h2 className="main-title mb-0 h2-titel">Boites mail</h2>         
        </div>

        <div className="bg-white card-box border-20">          
            <div className="d-flex justify-content-center mt-4">                  
                <Link to="/boites-mail-dashb" className="dash-btn-two tran3s me-3">Adresse mail 1</Link>
            </div> 
            <div className="d-flex justify-content-center mt-4">                  
                <Link to="/boites-mail-dashb" className="dash-btn-two tran3s me-3">Adresse mail 2</Link>
            </div> 
        </div> 
        <div className="bg-white card-box border-20">  
            <div className="d-flex justify-content-center mt-4">                  
                <Link to="/boites-mail-dashb" className="dash-btn-two tran3s me-3">Commander une nouvelle adresse</Link>
            </div> 
        </div>
               
      </div>      
    </div>
  );
};

export default MailIndex;